import HeaderComplementario from "./HeaderComplementario";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";

const TermsConditions = () => {
  return (
    <>
      <ScrollToTop />
      <HeaderComplementario />
      <div className="container-terms wrapper">
        <h1>Términos y Condiciones</h1>
        <h2>
          Aviso Legal <strong>Cerco Eléctrico Perimetral</strong>
        </h2>
        <p>
          Este web site ha sido creada por{" "}
          <a href="https://hgstudio.com.ar/" target="_blank">
            HG Studio{" "}
          </a>{" "}
          con carácter informativo y para uso personal. Accediendo a este web
          site o sus programas promocionales acepta los términos y condiciones
          indicados a continuación.{" "}
        </p>
        <p>
          {" "}
          1. Las cláusulas mencionadas seguidamente rigen los términos,
          condiciones y política de privacidad del presente web site.
        </p>
        <p>
          {" "}
          2. El acceso a este web site es responsabilidad exclusiva de los
          usuarios.
        </p>
        <p>
          {" "}
          3. El simple acceso a este web site directamente o a través de
          programas promocionales no supone entablar ningún tipo de relación
          negocial entre Cerco Eléctrico Perimetral y el usuario.
        </p>
        <p>
          4. El acceso y navegación en este web site implica el conocimiento y
          la inequívoca aceptación de todas las advertencias legales,
          condiciones y términos de uso contenidas en el mismo.
        </p>

        <h2>Actualidad</h2>
        <p>
          5. La información que aparece en este web site es la vigente en la
          fecha de su última actualización indicada.{" "}
          <strong>Cerco Eléctrico Perimetral</strong> se reserva el derecho a
          actualizar, modificar o eliminar la información de este web site.
        </p>
        <h2>Modificación de la información</h2>
        <p>
          6. Queda expresamente prohibido alterar, cambiar, modificar, o adaptar
          en cualquier forma este web site.
        </p>
        <p>
          7. Cerco Eléctrico Perimetral se reserva la facultad de realizar, en
          cualquier momento, cuantos cambios y modificaciones estime
          convenientes, pudiendo hacer uso de tal facultad en cualquier momento
          y sin previo aviso, en lo referente a la disponibilidad de productos y
          sus características. La disponibilidad de un concreto producto quedará
          condicionada a las existencias del mismo.
        </p>
        <p>
          8. <strong>Cerco Eléctrico Perimetral</strong> se reserva la facultad
          de modificar unilateralmente las condiciones y términos de uso de este
          web site. La entrada en vigor de las modificaciones de las condiciones
          de uso se producirá desde el momento de su publicación en este web
          site.
        </p>

        <h2>Contenidos</h2>
        <p>
          9. El sitio de <strong>Cerco Eléctrico Perimetral</strong> contiene
          información relativa a sus productos y las fotografias y descripciones
          pudieran referirse a productos descontinuados o no disponibles en este
          mercado. Cerco Eléctrico Perimetral realiza los máximos esfuerzos para
          evitar cualquier error en los contenidos que pudieran aparecer en este
          web site. <br /> <strong>Cerco Eléctrico Perimetral</strong> no
          garantiza, ni se responsabiliza de las consecuencias que pudieran
          derivarse de los errores en los contenidos que pudieran aparecer en
          este web site, aceptando expresamente la persona que accede a este web
          site la anterior exención de responsabilidad.
        </p>
        <p>
          10. <strong>Cerco Eléctrico Perimetral</strong> no asume
          responsabilidad alguna por las discrepancias que pudiesen existir
          entre los documentos impresos y la versión electrónica publicada en
          este web site,. En caso de discrepancia entre la versión impresa y la
          publicada en el web site, prevalecerá la versión impresa, salvo lo
          establecido respecto a erratas y actualizaciones.
        </p>
        <p>
          11. Las informaciones, contenidos y datos de cualquier clase en las
          páginas de éste web site se revisan antes de su publicación; sin
          embargo, no es posible garantizar que se encuentren absolutamente
          libres de erratas, errores de tipeo, defectos de composición y
          problemas equivalentes, por lo que{" "}
          <strong>Cerco Eléctrico Perimetral</strong> recomienda a los usuarios
          que estén atentos a las posibles actualizaciones o rectificaciones que
          el web site incorpore, que confirmen los datos que resulten de interés
          esencial para ellos a través de los cauces de comunicación personal,
          telefónica o por fax que se proporcionan en estas misma páginas y en
          los medios de comunicación y que, en ningún caso, adopten decisiones
          basadas única o especialmente en la información del web site.
        </p>
        <h2>Links en el web site</h2>
        <p>
          12.<strong>Cerco Eléctrico Perimetral</strong> no se responsabiliza en
          modo alguno de aquellos contenidos, actividades comerciales, productos
          y servicios incluidos que pudiesen visualizarse mediante enlaces
          electrónicos (links), directa o indirectamente, a través de este web
          site, en especial los que pudieran corresponder a contenidos de valor
          añadido para el usuario, como de ocio, entretenimiento, curiosidades,
          consejos, etc. y a aquellos web sites de marcas, en su caso,
          patrocinadas o colaboradoras de{" "}
          <strong>Cerco Eléctrico Perimetral</strong>
        </p>
        <p>
          13. La presencia de links en el web site de{" "}
          <strong>Cerco Eléctrico Perimetral</strong>
          salvo manifestación expresa en contrario, tiene una finalidad
          meramente informativa y en ningún caso supone sugerencia, invitación o
          recomendación sobre los mismos. Estos enlaces no representan ningún
          tipo de relación entre <strong>Cerco Eléctrico Perimetral</strong> y
          los particulares o empresas titulares de los web site a los que puede
          accederse mediante estos enlaces ni tampoco entre{" "}
          <strong>Cerco Eléctrico Perimetral</strong> y los centros o
          instituciones patrocinados por{" "}
          <strong>Cerco Eléctrico Perimetral</strong> o con los que la misma
          colabora. <strong> Cerco Eléctrico Perimetral</strong> se reserva el
          derecho de retirar de modo unilateral y en cualquier momento los links
          que aparecen en su web site.
        </p>
        <h2>Navegación, acceso y seguridad</h2>
        <p>
          14 . El acceso y navegación en este web site implica el conocimiento y
          aceptación inequívoca de las advertencias legales, condiciones y
          términos de uso contenidas en el mismo.{" "}
          <strong>Cerco Eléctrico Perimetral</strong>
          realiza los máximos esfuerzos para que la navegación se realice en las
          mejores condiciones y evitar los perjuicios de cualquier tipo que
          pudiesen ocasionarse durante la misma, pero no asume ningún tipo de
          responsabilidad en forma alguna que difiera de lo anterior o
          posteriormente indicado.
        </p>
        <p>
          15. El acceso a este web site se realiza en un entorno no seguro, por
          lo que la información se transmite de forma no cifrada.
        </p>
        <p>
          16. <strong>Cerco Eléctrico Perimetral</strong> no se responsabiliza
          ni garantiza que el acceso a este web site sea ininterrumpido o que
          esté libre de error. Tampoco se responsabiliza o garantiza que el
          contenido o software al que pueda accederse a través de este web site,
          esté libre de error o cause un daño. En ningún caso{" "}
          <strong>Cerco Eléctrico Perimetral</strong> será responsable por las
          pérdidas, daños o perjuicios de cualquier tipo que surjan por el
          acceso y el uso de la página web, incluyéndose pero no limitándose, a
          los ocasionados a los sistemas informáticos o los provocados por la
          introducción de virus. <strong>Cerco Eléctrico Perimetral</strong> no
          se hace responsable de los daños que pudiesen ocasionarse a los
          usuarios por un uso inadecuado de este web site o en condiciones
          distintas a las indicadas en el mismo.
        </p>
        <h2>Propiedad intelectual</h2>
        <p>
          17. Este web site y los contenidos que alberga se encuentran
          protegidos por las leyes de Propiedad Intelectual e Industrial. No
          podrán ser objeto de explotación, reproducción, distribución,
          modificación, comunicación pública, cesión o transformación en forma
          alguna. El acceso a este web site no otorga a los usuarios derecho, ni
          titularidad alguna sobre los derechos de propiedad intelectual e
          industrial de los contenidos que alberga este web site.
        </p>
        <p>
          18. El contenido de este web site puede ser descargado al terminal del
          usuario (download), siempre que sea para su uso privado inmediato y
          sin ningún fin comercial; por lo tanto no podrá explotar, reproducir,
          distribuir, modificar, comunicar públicamente, ceder, transformar o
          usar el contenido de este web site con fines públicos o comerciales.
        </p>
        <p>
          19. <strong>Cerco Eléctrico Perimetral</strong> no transfiere a los
          usuarios la propiedad de su software. El usuario es el propietario del
          soporte en el cual el software es grabado.{" "}
          <strong>Cerco Eléctrico Perimetral</strong>
          retiene todos los derechos de propiedad industrial e intelectual
          incluyendo el software. Si el usuario transfiere software de este web
          site a su terminal, no podrá en forma alguna diseccionarlo para su
          estudio, o descompilarlo, traducir la versión del código objeto
          original o su lenguaje a otro código o lenguaje.
        </p>
        <h2>Marcas registradas</h2>
        <p>
          20. Todas las marcas, logotipos y anagramas mostrados en este sitio
          son propiedad de <strong>Cerco Eléctrico Perimetral</strong> o de
          terceras empresas. Se prohíbe usarlos, descargarlos permanentemente,
          copiarlos o distribuirlos por cualquier medio sin el permiso escrito
          de <strong>Cerco Eléctrico Perimetral</strong> o de la tercera empresa
          titular de los mismos.
        </p>
        <h2>Acciones judiciales</h2>
        <p>
          21. <strong>Cerco Eléctrico Perimetral</strong> se reserva la
          posibilidad de ejercer las acciones judiciales que correspondan contra
          los usuarios que violen o infrinjan sus derechos de propiedad
          intelectual e industrial.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default TermsConditions;
