const Equipment = ({ children, picture, title }) => {
  return (
    <>
      <div>
        <div className="equipment-detail">
          <img src={picture} alt="" />
          <h1>{title}</h1>
          {children}
        </div>
      </div>
    </>
  );
};

export default Equipment;
