import Footer from "./Footer";
import HeaderComplementario from "./HeaderComplementario";
import nosotros from "../assets/nosotros.jpeg";

const WhoWeAre = () => {
  return (
    <>
      <HeaderComplementario />
      <div className="container-who-we-are wrapper">
        <div>
          <h1>Quienes Somos</h1>
          <p>
            CERCO ELECTRICO PERIMETRAL es una empresa joven, dinámica, que
            ofrece en su portfolio la seguridad como primer medida para el
            cuidado de la familia y los negocios. Nuestros principios centrales
            son la atención al cliente,máxima calidad de nuestro trabajo y
            precios transparentes. Le orientamos y nos ocupamos de forma
            responsable por su seguridad.
          </p>
          <p>
            Tenemos personal calificado que se ocupa de darle a su propiedad la
            atención solicitada. Empleamos las técnicas más actuales y los
            últimos avances en el sector.
          </p>
          <p>
            Todos nuestros trabajos cuentan con la protección de Federación
            Patronal Seguros S.A.
          </p>
          <p>No dudes en ponerte en contacto con nosotros..</p>
        </div>
        <img src={nosotros} alt="" />
        <div className="details-questions">
          <a
            href="https://api.whatsapp.com/send?phone=541133509246"
            target="_blank"
          >
            Contactanos
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WhoWeAre;
