import background from "../assets/video.mp4";
import responsive from "../assets/responsive-video.mp4";
import whatsapp from "../assets/whatsapp.png";
import alarma from "../assets/icons/alarma.png";
import servicio from "../assets/icons/serviciotecnico.png";
import control from "../assets/icons/control.png";
import celular from "../assets/icons/telefono.png";
import certificado from "../assets/icons/certificado.png";
import barrera from "../assets/icons/barrera.png";
import voltaje from "../assets/icons/voltaje.png";
import bateria from "../assets/icons/bateria.png";
import google from "../assets/google-opiniones.png";
import Iframe from "react-iframe";
import CarrouselBrand from "./CarrouselBrand";
import GaleryHome from "./GaleryHome";
import Typewriter from "typewriter-effect";
import fondo from "../assets/foto-responsive.jpeg";
import primerfondo from "../assets/primer-fondo.jpg";

const Main = () => {
  const benefitsItems = [
    {
      titulo: "Central de alarma",
      imagen: alarma,
    },
    {
      titulo: "Servicio Técnico",
      imagen: servicio,
    },
    {
      titulo: "Control remoto",
      imagen: control,
    },
    {
      titulo: "Encendido a distancia",
      imagen: celular,
    },
    {
      titulo: "Equipos homologados",
      imagen: certificado,
    },
    {
      titulo: "Sensasión disuasiva",
      imagen: barrera,
    },
    {
      titulo: "No provoca riesgos",
      imagen: voltaje,
    },
    {
      titulo: "Bateria de respaldo",
      imagen: bateria,
    },
  ];

  return (
    <>
      <div className="principal-container ">
        <div className="text">
          <Typewriter
            options={{
              strings: [
                "Sistema de seguridad altamente disuasivo y efectivo.",
                "Proteja su hogar y negocio con tecnología de avanzada.",
                "Una solución a la medida de cada lugar, contexto, dimensión.",
              ],
              autoStart: true,
              loop: true,
              skipAddStyles: true,
              wrapperClassName: "texts-spans",
              cursorClassName: "cursor-text",
              delay: 30,
              deleteSpeed: 10,
            }}
          />
        </div>
        <video
          className="background-video"
          autoPlay
          loop
          muted
          playsInline
          poster={primerfondo}
        >
          <source src={background} type="video/mp4" />
        </video>

        <video
          className="video-responsive"
          poster={fondo}
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={responsive} type="video/mp4" />
        </video>
      </div>
      <div className="google-reviews wrapper">
        <div className="cotizacion-container">
          <h1>
            Cotizá por whatsapp <br /> tu cerco eléctrico
          </h1>
          <a
            href="https://api.whatsapp.com/send?phone=541133509246"
            target="_blank"
          >
            <img src={whatsapp} alt="" />
            11 3350 - 9246
          </a>
          <p>Atención personalizada, visita y presupuesto sin cargo.</p>
        </div>
        <div className="google-container">
          <h1>Google Reviews</h1>
          <div className="info-google">
            <img src={google} alt="" />
            <a href="https://www.google.com/search?q=cerco%20electrico%20perimetral&rlz=1C5CHFA_enAR1021AR1021&oq=cerco+&aqs=chrome.0.69i59l3j69i57j0i433i512j69i60l3.1869j1j7&sourceid=chrome&ie=UTF-8&tbs=lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:2&tbm=lcl&sxsrf=ALiCzsa1ec4mUtknpU2cHv7ETsy0iVBIEA:1669667828379&rflfq=1&rldimm=14606765724500410326&lqi=ChpjZXJjbyBlbGVjdHJpY28gcGVyaW1ldHJhbEjo06Lg27eAgAhaKBAAEAEQAhgAGAEYAiIaY2VyY28gZWxlY3RyaWNvIHBlcmltZXRyYWySARhzZWN1cml0eV9zeXN0ZW1fc3VwcGxpZXI&ved=2ahUKEwiso7au3dH7AhWbrZUCHc1SB40QvS56BAhbEAE&sa=X&rlst=f&pccc=1#rlfi=hd:;si:14606765724500410326,l,ChpjZXJjbyBlbGVjdHJpY28gcGVyaW1ldHJhbEjo06Lg27eAgAhaKBAAEAEQAhgAGAEYAiIaY2VyY28gZWxlY3RyaWNvIHBlcmltZXRyYWySARhzZWN1cml0eV9zeXN0ZW1fc3VwcGxpZXI;mv:[[-34.456076599999996,-58.2696111],[-34.7302239,-58.7241891]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:2">
              Más opiniones de Google{" "}
            </a>
          </div>
        </div>
      </div>
      <div className="benefits-container wrapper">
        {benefitsItems.map((item) => (
          <div className="benefits-details">
            <img src={item.imagen} alt="icons-benefits" />
            <p>{item.titulo}</p>
          </div>
        ))}
      </div>

      <CarrouselBrand />
      <GaleryHome />
      <div className="contact-map">
        <Iframe
          url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3282.229837041446!2d-58.5037386845025!3d-34.648898067397454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcc9a972673857%3A0x7662a3d00d41f273!2sPola%201100%2C%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1669601535222!5m2!1ses-419!2sar"
          width="100%"
          height="300px"
          id=""
          display="block"
          position="relative"
          frameborder="0"
        />
      </div>
    </>
  );
};

export default Main;
