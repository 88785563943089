import axios from "axios";

const BASE_URL = "https://cercoelectricoperimetral.com";

export const fetchGalleries = async (random) => {
  const url = random ? `get-galleries.php?random=true` : `get-galleries.php`;
  const params = { url: `${BASE_URL}/api/${url}` };

  try {
    const response = await axios(params);

    return response.data;
  } catch (err) {
    return [];
  }
};
