import { useState } from "react";
import HeaderComplementario from "./HeaderComplementario";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import Footer from "./Footer";
import axios from "axios";
import { objectToFormData } from "../utils";

const Contact = () => {
  const [recaptchavalue, setRecaptchaValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [fields, setFields] = useState({
    name: "",
    email: "",
    phone: "",
    matter: "",
    message: "",
  });

  const handleChangeField = (field, value) => {
    setFields((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const submitDisabled = () => {
    if (loading) return true;

    const formFields = Object.keys(fields);
    let empty = false;

    for (let field of formFields) {
      const value = fields[field].trim();
      if (value === "") {
        empty = true;
        break;
      }
    }

    return empty;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      const response = await axios({
        method: "POST",
        url: `https://www.cercoelectricoperimetral.com/api/mail.php`,
        data: objectToFormData({
          nombre: fields.name,
          correo: fields.email,
          telefono: fields.phone,
          asunto: fields.matter,
          mensaje: fields.message,
          "g-recaptcha-response": recaptchavalue,
        }),
      });

      if (response.data.status !== "ok") throw new Error();

      Swal.fire({
        title: "Mensaje enviado!",
        text: "Se ha enviado correctamente!",
        icon: "succes",
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("Lo sentimos, se ha producido un error, intente nuevamente.");
    }
  };

  return (
    <>
      <HeaderComplementario />
      <div className="container-contacto">
        <div className="contact-col"></div>
        <div className="container-detail-contacto wrapper">
          <h1>Contactanos</h1>
          <form className="form-container" onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                value={fields.name}
                onChange={(e) => handleChangeField("name", e.target.value)}
                placeholder="Nombre"
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                value={fields.email}
                onChange={(e) => handleChangeField("email", e.target.value)}
                placeholder="Email"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                value={fields.phone}
                onChange={(e) => handleChangeField("phone", e.target.value)}
                placeholder="Teléfono"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                value={fields.matter}
                onChange={(e) => handleChangeField("matter", e.target.value)}
                placeholder="Asunto"
              />
            </div>
            <div className="form-group">
              <textarea
                name="menssage"
                cols="30"
                rows="10"
                type="text"
                value={fields.message}
                onChange={(e) => handleChangeField("message", e.target.value)}
                placeholder="Mensaje"
              ></textarea>
            </div>
            {error && <p>{error}</p>}{" "}
            <ReCAPTCHA
              className="recaptcha"
              sitekey="6Le94WUUAAAAAMGt_wxGtWwaqdti-D9A5jh5k2xW"
              onChange={setRecaptchaValue}
            />
            <button className="button-contact" disabled={submitDisabled()}>
              ENVIAR
            </button>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
