import React, { useState } from "react";
import Impact from "./Impact";
import HeaderComplementario from "../HeaderComplementario";
import Footer from "../Footer";
import RevolutionControl from "./RevolutionControl";
import ShockPremium from "./ShockPremium";
import ShockPremiumComunic from "./ShockPremiumComunic";

const Equipments = () => {
  /* const [openVideo, setOpenVideo] = useState(false);
  const handleClick = () => {
    setOpenVideo(!openVideo); 
  };*/
  return (
    <>
      <HeaderComplementario />
      <div className="equipment-container wrapper">
        <h1 className="equipment-principal">Equipos</h1>
        <div className="details-container-info">
          {/*  <Impact /> */}
          <RevolutionControl />
          <ShockPremium />
          <ShockPremiumComunic />
        </div>
        <div className="iframe-video-open">
          <div>
            <iframe
              width="1270px"
              height="720px"
              src="https://www.youtube.com/embed/rXZm4iSuPFA"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

Equipments.propTypes = {};

export default Equipments;
