import { Link } from "react-router-dom";
import React from "react";

const NavBar = () => {
  return (
    <nav>
      <div className="nav-container wrapper">
        <ul>
          <li>
            <Link className="nav-link" to="/quienessomos">
              Quienes somos
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/galeria">
              {" "}
              Galerías
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/equipos">
              {" "}
              Equipos
            </Link>
          </li>
          <li>
            <a
              href="https://cercoelectricoperimetral.com/blog/"
              className="nav-link"
              target="_blank"
            >
              {" "}
              Blog
            </a>
          </li>
          <li>
            <Link className="nav-link" to="/funcionamiento">
              {" "}
              Funcionamiento
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/contacto">
              {" "}
              Contacto
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
