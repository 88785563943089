import GaleryItem from "./GaleryItem";
import HeaderComplementario from "./HeaderComplementario";
import Footer from "./Footer";
import { useState, useEffect } from "react";
import { fetchGalleries } from "../request";

const Galery = () => {
  const [pictures, setPictures] = useState([]);
  useEffect(() => {
    const getPictures = async () => {
      const pics = await fetchGalleries();
      setPictures(pics);
    };

    getPictures();
  }, []);

  return (
    <>
      <div className="galeria-principal-container">
        <HeaderComplementario />

        <div className="galery-home-container">
          {pictures.map((item) => (
            <GaleryItem item={item} key={item.name} />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Galery;
