import logo from "../assets/logo-header.png";
import cerrar from "../assets/x.png";
import { Link } from "react-router-dom";

const HeaderComplementario = () => {
  return (
    <div className="container-header-complementario">
      <div className="details-header-complementario wrapper">
        <Link to="/">
          <img
            className="logo-principal-complementario"
            src={logo}
            alt="Logo de Cerco Perimetral"
          />
        </Link>

        <Link to="/">
          <img className="close-header-complementario" src={cerrar} alt="" />
        </Link>
      </div>
    </div>
  );
};

export default HeaderComplementario;
