import Footer from "./Footer";
import HeaderComplementario from "./HeaderComplementario";
import ScrollToTop from "./ScrollToTop";

const FrecuentQuestions = () => {
  return (
    <>
      <ScrollToTop />
      <HeaderComplementario />
      <div className="container-frecuent-questions wrapper">
        <h1>Preguntas Frecuentes</h1>
        <h2>¿Por que se afirma que No es Letal?</h2>
        <h3>
          {" "}
          Fundamentalmente por que el equipo Energizador “ELIMINA EL AMPERAJE
          DEJANDOLO EN CERO”, es este (amperaje) que causa las quemaduras paros
          cardiacos y/o muerte.
        </h3>
        <h2>¿Que tipo de corriente (voltaje utiliza)?</h2>
        <h3>
          Discontinua (si alguien toca una o más hebras le proporciona un fuerte
          golpe de voltaje, soltándolo instantáneamente).
        </h3>
        <h2>¿Cuánto consume un Cerco Eléctrico?</h2>
        <h3>Consume 6.5 Watts /Hora aprox. (Bajo consumo).</h3>
        <h2>
          ¿Que pasa con el Cerco Eléctrico si se topa o corta uno de los cables?
        </h2>
        <h3>
          En el caso de que corten o topen un cable, se activa de inmediato La
          Sirena.
        </h3>
        <h2>¿Si se corta la Luz sigue funcionando?</h2>
        <h3>
          El Cerco Eléctrico cuenta con Una batería de respaldo de 12 Volts y 7
          Ampere, en caso de cortes de luz se activa automáticamente por lo cual
          siempre está en funcionamiento. Aún con cortes de luz.
        </h3>
        <h2>¿Por que se afirma que es el sistema MÁS DISUASIVO QUE EXISTE?</h2>
        <h3>
          Fundamentalmente porque actúa “ANTES QUE EL INDIVIDUO INTENTE SIQUIERA
          INGRESAR A SU PERIMETRO PUESTO QUE CAUSA UN NATURAL TEMOR”.
        </h3>
        <h2>¿Que pasa con los pájaros?</h2>
        <h3>
          Los pájaros se posan en una sola hebra, un sistema eléctrico requiere
          de un ciclo positivo –negativo para que se active, por ello a los
          pájaros no les afecta.
        </h3>
        <h2>¿Causa daños a las personas o animales?</h2>
        <h3>
          El Cerco Eléctrico no causa daño físico alguno, solo trabaja en la
          condición de “Umbral de Pánico” que son aquellas desagradables
          sensaciones de electrocución que el ser humano ha experimentado en su
          vida, pero con la notable diferencia de no ser letal.
        </h3>
        <h2>¿La batería de respaldo que lleva cuantas horas funciona?</h2>
        <h3>Debido a su bajo consumo tiene una duración de 48 horas.</h3>
        <h2>
          ¿Se debe recargar la Batería si esta funciona en caso de cortes luz?
        </h2>
        <h3>
          {" "}
          No, la batería se recarga automáticamente una vez se repone la luz.
        </h3>
        <h2>¿Cuanto se demora una instalación?</h2>
        <h3>
          Depende de la cantidad de metros, por lo general se realizan en el
          día.
        </h3>
        <h2>¿Los equipos son en Comodato?</h2>
        <h3> NO. los equipos pasan a ser propiedad del cliente.</h3>
        <h2>
          ¿Por qué deben instalarse los letreros Amarillo que dice Peligro Cerco
          Eléctrico?
        </h2>
        <h3>
          {" "}
          El principio fundamental del CERCO ELÉCTRICO ES DISUADIR , la
          instalación de letreros avisa y previene a quien lo visualice del
          Peligro al cual se expone.
        </h3>
        <h2>
          ¿Con este sistema se puede garantizar que no robarán o que no
          ingresarán los delincuentes?
        </h2>
        <h3>
          No existe ningún sistema que garantice que los delincuentes no
          intenten o no roben, en general los sistemas de seguridad minimizan
          las probabilidades. Sin embargo, existe consenso respecto a que el
          Cerco Eléctrico es el más disuasivo de todos los existentes.
        </h3>

        <div className="details-questions">
          <p className="doubts">
            Si tenés alguna otra duda, no dudes en consultarnos...
          </p>
          <a
            href="https://api.whatsapp.com/send?phone=541133509246"
            target="_blank"
          >
            Consultar
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FrecuentQuestions;
