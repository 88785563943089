import HeaderComplementario from "./HeaderComplementario";
import Footer from "./Footer";
import funcionamiento from "../assets/funcionamiento.jpeg";

const Functioning = () => {
  return (
    <>
      <HeaderComplementario />

      <div className="container-functioning wrapper">
        <div>
          <h1>Funcionamiento</h1>
          <p>
            Los cercos eléctricos son alambres tensos en línea cubriendo un
            perímetro y conectados a una central de comando que emite pulsos de
            descargas eléctricas impidiendo ser cruzadas.
          </p>
          <p>
            Los cercos eléctricos son altamente disuasivos ya que el intruso ve
            al sistema como una amenaza de su integridad física
          </p>
          <p>
            La central de comando de los cercos eléctricos detectan un corte de
            alguno de los alambres como también si ha realizado una descarga
            eléctrica, y se puede asociar a sirenas, activación de luces,
            discado de teléfonos para aviso a distancia, iniciar la filmación de
            sistemas de cámaras.
          </p>
          <p>
            Incluye una batería para darle autonomía al cerco eléctrico en
            cortes de suministro.
          </p>
          <p>
            El cerco eléctrico puede desconectarse a por llave manual o a
            control remoto.
          </p>
        </div>
        <img src={funcionamiento} alt="" />
        <div className="details-questions">
          <a
            href="https://api.whatsapp.com/send?phone=541133509246"
            target="_blank"
          >
            Conocer más
          </a>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Functioning;
