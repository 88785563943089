import GaleryItem from "./GaleryItem";
import { useState, useEffect } from "react";
import { fetchGalleries } from "../request";

const GaleryHome = () => {
  const [pictures, setPictures] = useState([]);
  useEffect(() => {
    const getPictures = async () => {
      const pics = await fetchGalleries(true);
      setPictures(pics);
    };

    getPictures();
  }, []);
  return (
    <div className="galery-home-container">
      {pictures.map((item) => (
        <GaleryItem item={item} key={item.name} />
      ))}
    </div>
  );
};

export default GaleryHome;
