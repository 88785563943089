import { useState, useEffect } from "react";

const AppContainer = () => {
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    setTimeout(() => setShowPopup(false), 2000);
  }, []);

  return (
    <>
      <div className={`popup ${showPopup ? "popup-visible" : ""} `}></div>
    </>
  );
};

export default AppContainer;
