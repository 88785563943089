import { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const GaleryItem = ({ item }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button onClick={() => setOpen(true)} className="galery-home-details">
        <img src={item?.fotos[0]} alt="" />
        <div className="text-galery">
          <span>{item.name}</span>
        </div>
      </button>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={item.fotos.map((item) => ({ src: item }))}
      />
    </>
  );
};

export default GaleryItem;
