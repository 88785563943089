import Equipment from "./Equipment";
import equipos from "../../assets/equipos/shock-premium.png";

const ShockPremiumComunic = () => {
  return (
    <Equipment picture={equipos} title="Shock Premium Comunic">
      <p className="sub-equipment">Su alarma con choque</p>
      <ul>
        <li>Discadora tono y pulso embutida;</li>
        <li>Teclado en el panel;</li>
        <li>Tensión de choque: 18.000V / 20.000V / 22.000V;</li>
        <li>Energía emitida: 2,5 joules;</li>
        <li>Tamaño máximo de la cerca: 5.000 metros lineales;</li>
        <li>Forma de accionamiento: control remoto;</li>
        <li>2 sectores mixtos e independientes;</li>
        <li>Función pánico por control.</li>
      </ul>
      <h1>Especificaciones Técnicas</h1>
      <ul className="second-list">
        <li>Zonas configurables (instantánea, inteligente o temporizado);</li>

        <li> Índice de protección IPX4;</li>
        <li>Salida de LED;</li>
        <li>
          Entrada para dispositivo externo (teclado, receptor, bota, etc);
        </li>
        <li>Ajuste de los pulsos fallidos;</li>
        <li>Shutdown de sirena programable;</li>
        <li>Shutdown de choque programable;</li>
        <li>Tiempo de sirena ajustable;</li>
        <li>Beep de sirena programable;</li>
        <li>
          Permite integrar el control vía smartphone cuando interconectado con
          alarma en la línea Genno Cloud con GPRS e Ethernet/Wifi.
        </li>
      </ul>
    </Equipment>
  );
};

export default ShockPremiumComunic;
