import Equipment from "./Equipment";
import equipos from "../../assets/equipos/revolution.png";

const RevolutionControl = () => {
  return (
    <Equipment picture={equipos} title="Revolution Control">
      <p className="sub-equipment">La central que revolucionó el mercado</p>
      <ul>
        <li>Tensión de choque: 8.000V / 10.000V / 12.000V;</li>
        <li>Energía emitida: 0,6 joules;</li>
        <li>Tamaño máximo de la cerca: 3.100 metros lineales;</li>
        <li>Forma de accionamiento: control remoto;</li>
        <li>2 sectores mixtos e independientes;</li>
        <li>Función pánico por control.</li>
      </ul>
      <h1>Especificaciones Técnicas</h1>
      <ul className="second-list">
        <li> Índice de protección IPX4;</li>
        <li>Salida de LED;</li>
        <li>
          Entrada para dispositivo externo (teclado, receptor, bota, etc);
        </li>
        <li>Ajuste de los pulsos fallidos;</li>
        <li>Shutdown de sirena programable;</li>
        <li>Shutdown de choque programable;</li>
        <li>Tiempo de sirena ajustable;</li>
        <li>Beep de sirena programable;</li>
        <li>
          Permite integrar el control vía smartphone cuando interconectado con
          alarma en la línea Genno Cloud con GPRS e Ethernet/Wifi.
        </li>
      </ul>
    </Equipment>
  );
};

export default RevolutionControl;
