import { useState } from "react";

const HamburguerMenu = ({ handleClick, classname }) => {
  return (
    <div>
      <button onClick={handleClick} className={classname}>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  );
};

export default HamburguerMenu;
