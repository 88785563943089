import "./App.css";
import Home from "./components/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TermsConditions from "./components/TermsConditions";
import FrecuentQuestions from "./components/FrecuentQuestions";
import Functioning from "./components/Fuctioning";
import AppContainer from "./components/AppContainer";
import WhoWeAre from "./components/WhoWeAre";
import Equipments from "./components/Equipment/Equipments";
import Contact from "./components/Contact";
import React from "react";
import Galery from "./components/Galery";

function App() {
  return (
    <div>
      <AppContainer />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<TermsConditions />} />
          <Route path="/questions" element={<FrecuentQuestions />} />
          <Route path="/funcionamiento" element={<Functioning />} />
          <Route path="/quienessomos" element={<WhoWeAre />} />
          <Route path="/equipos" element={<Equipments />} />
          <Route path="/contacto" element={<Contact />} />
          <Route path="/galeria" element={<Galery />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
